:root {
  --primary-color: #6552f9;
  --complimentary-color: #ffffff;
  --main-background-color: #cccccc;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--main-background-color);
  line-height: 1.5;
}

h1 {
  font-family: 'Righteous', monospace;
}
h2 {
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  height: 4rem;
  padding: 1rem 2rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

a:visited {
  color: #222;
}
.button:visited {
  color: var(--complimentary-color);
}

img {
  max-width: 100%;
}

p {
  margin-top: 0;
}

button,
.button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  -webkit-appearance: button;
  outline: none !important;
  border: none !important;
  background-color: var(--primary-color);
  color: var(--complimentary-color);
  border-radius: 8px;
  padding: 0.75rem 2rem;
  text-decoration: none;
  cursor: pointer;
}

@media print {
  body {
    background-color: var(--primary-color);
  }
  header,
  .hidden-for-print {
    display: none;
  }
}
@page {
  size: auto;
  margin: 0;
}
